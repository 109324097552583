<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
    <!-- <iframe
      src="http://localhost:5000/silence.mp3"
      type="audio/mp3"
      allow="autoplay"
      id="iframeaudio"
      style="display: none"
    ></iframe> -->
    <!-- <dashboard-core-settings /> -->
  </v-app>
</template>

<script>
export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    DashboardCoreSettings: () => import("./components/core/Settings"),
    DashboardCoreView: () => import("./components/core/View"),
  },

  data: () => ({
    expandOnHover: false,
  }),
  mounted() {
    const token = sessionStorage.getItem("user-token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>
